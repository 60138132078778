import { RequestDetail } from './../../models/request-detail';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { DocumentService } from 'src/app/services/document.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { Document } from 'src/app/models/document';
import { saveAs } from 'file-saver';
import { ReplyService } from 'src/app/services/reply.service';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./request-detail.component.scss']
})
export class RequestDetailComponent implements OnInit {
  siteUrl = environment.siteUrl;
  showV3 = environment.showV3;
  requestDetail: RequestDetail;
  advisorPage: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private utilService: UtilService,
    private requestService: RequestService,
    private documentService: DocumentService,
    public breakpointService: BreakpointService,
    public replyService: ReplyService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get("id")!== null) {
        if(this.replyService.advisorPage) {
          this.getRequestDetailForAdvisor(params.get("id"));
        } else {
          this.getRequestDetail(params.get("id"));
          this.requestService.selectedId = Number(params.get("id"));
        }
      }
    });
  }

  getRequestDetailForAdvisor (id) {
    this.requestService.getRequestDetailForAdvisor(id).subscribe(resp => { this.requestDetail = resp;
        this.requestService.requests = this.sort(this.requestService.requests.slice());
    });
  }
  getRequestDetail(id) {
    this.requestService.getRequestDetail(id).subscribe(resp => {
        this.requestDetail = resp;
        if (this.requestService.requests.length) {
            this.requestService.requests = this.sort(this.requestService.requests.slice());
        }
        if (this.requestService.requestsPFN.length) {
            this.requestService.requestsPFN = this.sort(this.requestService.requestsPFN.slice());
        }
    });
  }

  formatDateTime(date: Date) {
    return this.utilService.formatDateTime(date, this.configService.lang);
  }
  statusLabel(status: string) {
    return this.utilService.getStatusLabel(status);
  }

  back(){
    this.router.navigate(['/'], { skipLocationChange: true });
  }

  sort(data) {
    return data.sort(function(x, y) {
      if (x.unread === y.unread) {
        return y.creationDateTime > x.creationDateTime;
      }
      return y.unread - x.unread;
    });
  }

  documentIcon(doc: Document) {
    const nameParts = doc.name.split('.');
    if (nameParts[1] === 'pdf') {
        return 'icon_pdf';
    } else if (nameParts[1] === 'jpg' || nameParts[1] === 'jpeg') {
        return 'icon_jpg';
    } else if (nameParts[1] === 'doc' || nameParts[1] === 'docx' || nameParts[1] === 'docm') {
        return 'icon_word';
    } else if (nameParts[1] === 'xls' || nameParts[1] === 'xlsx' || nameParts[1] === 'xlsm') {
        return 'icon_excel';
    }
     else {
      return 'icon_file';
    }
  }

  downloadDocument(doc: Document, requestDetail: RequestDetail) {
      if(requestDetail.downloadable){
        if(/msie\s|trident\//i.test(window.navigator.userAgent)) {
          this.documentService.downloadDocumentIE(doc.documentId).subscribe(blob => {
            saveAs(blob,doc.name)
            });
        } else {
          this.documentService.downloadDocument(doc.documentId)
              .subscribe(
                    (response: any) =>{
                          let mySrc;
                          let docNameEXM;
                          if(this.configService.extranet === 'EXM' || this.configService.extranet === 'EIM' ) {
                              docNameEXM ='data:application/octet-stream;fileName:' + doc.name + ';';
                          }
                          const reader = new FileReader();
                          reader.readAsDataURL(response);
                          reader.onloadend = function() {
                              // result includes identifier 'data:image/png;base64,' plus the base64 data
                              mySrc = reader.result;
                              if(docNameEXM !== undefined) {
                                  mySrc = (<string>mySrc).replace('data:application/octet-stream;',docNameEXM);
                              }
                              let downloadLink = document.createElement('a');
                              downloadLink.href = mySrc;
                              if (doc.name){
                                  downloadLink.setAttribute('download', doc.name);
                              }
                              document.body.appendChild(downloadLink);
                              downloadLink.click();
                          }

                      });
                    }
      } else if(this.replyService.advisorPage) {
          this.documentService.downloadDocumentAdvisor(doc.documentId)
              .subscribe(blob => {
              saveAs(blob,doc.name)
              });
      }
  }

  formatBytes(bytes){
      if (bytes === 0) return '0 Ko';

      const k = 1024;
      const sizes = ['Ko', 'Ko', 'Mo'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  sendMessage() {
    this.replyService.currentId = this.requestDetail.request.id;
    if(!this.replyService.advisorPage) {
      this.replyService.group = { id: this.requestDetail.request.groupId, 
                                    nameFR: this.requestDetail.request.groupNameFR, 
                                    nameEN: this.requestDetail.request.groupNameEN, 
                                    types: null,
                                    creation: true,
                                    consultation: true };
      this.replyService.type = { id: this.requestDetail.request.typeId, 
                                    nameFR: this.requestDetail.request.typeNameFR, 
                                    nameEN: this.requestDetail.request.typeNameEN,
                                    creation: true,
                                    consultation: true };
    }
  }

}




