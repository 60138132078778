import { ModalService } from './services/modal.service';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Modal } from './models/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from './services/config.service';
import { LocalizationService } from './internationalization/localization.service';
import { OAuthErrorEvent, OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import { authCodeFlowConfig } from './core/auth/auth-config';
import { authCodeFlowConfigAzure } from './core/auth/auth-config-azure';
import { authCodeFlowConfigEmbark } from './core/auth/auth-config-embark';
import { HttpResponse } from '@angular/common/http';
import { Config } from './models/config';
import {Observable, Subject} from 'rxjs';


@Component({
  selector: 'app-gsms',
  templateUrl: './app.component.html',
  styleUrls: ['../styles/bootstrap.min.css', './app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'messagerie-front';
  modal: Modal;
  siteUrl = environment.siteUrl;
  environment = environment.name;
  hideEnvironment = (environment.name === 'prod' || environment.name === 'pprod');
  isAdvisor = location.href.includes(environment.advisorUrl);
  configResponse: HttpResponse<Config>;
  selectedStyle: string;
  authenticated$: Subject<boolean> = new Subject<boolean>();
  private searchParams: URLSearchParams;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private configService: ConfigService,
    private elementRef: ElementRef,
    private localizationService: LocalizationService,
    private oauthService: OAuthService,
  ) {}

  ngOnInit() {
    this.setSearchParams();
    this.oauthPrep();
    this.tryLogin()
    .catch(err => {
      console.error('ngOnInit tryLogin error', err);
    })
    .then(() => {
      this.getConfig();
    });
  }

  private oauthPrep() {
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'token_received':
            if (this.oauthService.state) {
                this.router.navigateByUrl(decodeURIComponent(this.oauthService.state));
            }
            break;
        }
    });

    const language = this.elementRef.nativeElement.getAttribute('data-language') || 'fr-FR';
    if (language){
      localStorage.setItem('language', language);
    }
    let extra = this.elementRef.nativeElement.getAttribute('data-extra') || 'EXA';
    this.route.queryParams.subscribe(params => {
        if (params.extranet) {
            extra = params.extranet;
        }
        if (extra === 'ERH') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlERH;
        } else if (extra === 'ERH-PQL') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlERHPQL;
        } else if (extra === 'EAC') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEAC;
        } else if (extra === 'EXA'){
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEXA;
        } else if (extra === 'EXI'){
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEXA;
        } else if (extra === 'EXM') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEXM;
        } else if (extra === 'EXM-embark') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEmbarkEXM;
        } else if (extra === 'EIM') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEIM;
        } else if (extra === 'EIM-embark') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEmbarkEIM;
        } else if (extra === 'EAS') {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEAS;
        } else {
            this.configService.ssoRedirectUrl = environment.ssoRedirectUrl;
        }
        if (extra === 'EXA-dev'){
            this.configService.extranet = 'EXA';
        } else {
            this.configService.extranet = extra;
        }
        this.configService.isEmbark = this.configService.extranet === 'EAS' || this.configService.extranet === 'EXM-embark' || this.configService.extranet === 'EIM-embark';
        this.configService.isMobile = this.configService.extranet === 'EXM' || this.configService.extranet === 'EIM' || this.configService.extranet === 'EXM-embark' || this.configService.extranet === 'EIM-embark';
        if (this.configService.lang === 'en-US' || language === 'en-US'){
            this.configService.ssoRedirectUrl = this.configService.ssoRedirectUrl.replace('/fr/', '/en/');
        }
    });

    if (environment.azureAdEnabled && this.isAdvisor) {
      this.oAuthConfigAzure();
    } else if (environment.ssoEnabled) {
        this.oAuthConfig();
    }

    // already should be false because of this.configService.clean() from ngOnDestroy, but let's be sure
    this.configService.ssoRedirectFlag = false;
  }

  private tryLogin() {
    console.log('app component tryLogin',  this.oauthService.hasValidAccessToken());
    if (this.configService.isEmbark) {
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      return this.oauthService.loadDiscoveryDocumentAndTryLogin();
    } else {
      return this.oauthService.tryLogin();
    }
  }

  private getConfig(): void  {
    console.log('hasValidAccessToken (getConfig)', this.oauthService.hasValidAccessToken());
    if ((this.oauthService.hasValidAccessToken() || !environment.ssoEnabled) && !this.isAdvisor) {
      this.configService.idToken = this.oauthService.getIdToken();
      this.configService.getConfig().subscribe(
        response => {
          this.configResponse = response;
          console.log('hasValidAccessToken - goOn');
          console.log('idToken: ', this.oauthService.hasValidIdToken());
          this.goOn();
        },
        error => {
          console.error('Get config error', error);
        });
    } else if (this.oauthService.hasValidAccessToken() && this.isAdvisor) {
      console.log('Advisor hasValidAccessToken - goOn');
      this.configService.advisorIdToken = this.oauthService.getIdToken();
      this.goOn();
    } else {
      this.configService.ssoRedirectFlag = true;
      if (this.isAdvisor) {
        if (this.searchParams.has('id')) {
          console.log('initCodeFlow start - advisor');
          this.oauthService.initCodeFlow(this.searchParams.get('id'));
        }
      } else {
        console.log('initCodeFlow start');
        this.oauthService.initCodeFlow();
      }
    }
  }

  // query params of the current url
  private setSearchParams(): void {
    const url = new URL(decodeURIComponent(document.location.href));
    this.searchParams = new URLSearchParams(url.search);
  }

  private goOn(): void {
    if (this.configResponse) {
      this.configService.bffToken = this.configResponse.headers.get('msx-bff-token');
      this.configService.domain = this.configResponse.body.domain;
      this.configService.lang = (this.configResponse.body.lang === 'FRA') ? 'fr-FR' : 'en-US';
      localStorage.setItem('language', this.configService.lang);
      this.localizationService.initService();
      this.configService.profileName = this.configResponse.body.profileName;
      this.configService.attachmentRules = this.configResponse.body.attachmentRules;
    }

    this.modalService.modal$.subscribe(m => {
      this.modal = m;
    });

    this.localizationService.initService();

    this.googleFontsLink();

    const customLandingPage = this.elementRef.nativeElement.getAttribute('data-navigate');
    if (customLandingPage) {
        switch (customLandingPage) {
            case 'creation':
                this.configService.customLandingPage = 'creation';
                this.router.navigate(['/creation'], {skipLocationChange: true});
                break;
        }
    }
    if (this.oauthService.state && this.isAdvisor) {
      this.router.navigate(['/advisor'], { queryParams: {id: this.oauthService.state}});
    }

    this.router.initialNavigation();
  }

  hideModal(choice: boolean = false): void {
    this.modalService.hide(choice);
  }

  oAuthConfig(): void {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
        this.router.navigate(['/'],
                        {
                        relativeTo: this.route,
                        queryParams: {}
                      });
        this.oauthService.logOut();
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });
    let authConfig = authCodeFlowConfig;
    if (this.configService.isEmbark) {
      authConfig = authCodeFlowConfigEmbark;
    }
    authConfig.redirectUri = this.configService.ssoRedirectUrl;
    if (this.configService.extranet === 'EAS') {
      authConfig.redirectUri = window.location.protocol + '//' + window.location.host + '/connect/messagerie';
    }

    this.oauthService.configure(authConfig);
  }

  oAuthConfigAzure(): void {
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuthErrorEvent Object:', event);
        } else {
          console.warn('OAuthEvent Object:', event);
        }
      });
      this.oauthService.configure(authCodeFlowConfigAzure);
      // should not be necessary anymore
      // this.oauthService.tryLogin().then(()=> {
      //   if (!this.oauthService.hasValidAccessToken()) {
      //     this.oauthService.initCodeFlow();
      //   }
      // }).then(() => {
      //   let claims = this.oauthService.getIdentityClaims();
      // })
    }

   openPost(url): void {
      const formElement = document.createElement('form');
      formElement.setAttribute('method', 'post');
      formElement.setAttribute('action', url);
      formElement.setAttribute('target', '_parent');
      document.body.appendChild(formElement);
      formElement.submit();
  }

  ngOnDestroy() {
    if (this.oauthService.hasValidAccessToken() && this.router.url !== '/') {
      this.configService.clean();
      this.oauthService.logOut();
    }
  }

  googleFontsLink() {
    const head = document.getElementsByTagName('head')[0];
    const link1 = document.createElement( 'link' );
    link1.href = 'https://fonts.googleapis.com';
    link1.rel = 'preconnect';
    head.appendChild(link1);
    const link2 = document.createElement( 'link' );
    link2.href = 'https://fonts.gstatic.com';
    link2.rel = 'preconnect';
    link2.crossOrigin = '';
    head.appendChild(link2);
    const link = document.createElement( 'link' );
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap';
    link.rel = 'stylesheet';
    head.appendChild(link);
  }

  public isAuthenticated(): Observable<boolean> {
		if (
			this.oauthService.hasValidAccessToken()
			&& this.oauthService.hasValidIdToken()
		) {
			this.authenticated$.next(true);
			return this.authenticated$;
		}

		this.authenticated$.next(false);
		return this.authenticated$;
	}
}
