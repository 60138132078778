
  <div  class="container-msx-fluid messagerie-container" *ngIf="!breakpointService.isDesktop">
    <div class="row-msx">
      <div class="col-12">
        <img class="back" (click)= "back()" src='{{siteUrl}}/assets/icon_left-arrow.svg'>
        <h4 class="h4-msx">{{ 'request-detail.backToMailBox' | translate }}</h4>
      </div>
    </div>
  </div>
  <div class="req-container" *ngIf="requestDetail && requestDetail.request as request" [ngClass]="{'mobile': !breakpointService.isDesktop}">
    <div class="row-msx">
      <div class="col-12 title-line d-flex justify-content-start align-items-center">
        <h2 class="h2-msx">
          {{ configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN }}
        </h2>
        <div class="req-sub-status" *ngIf="request.subStatus">{{ request.subStatus }}</div>
        <div class="request-status" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-received': request.status === 'RECEIVED', 'request-status-closed': request.status === 'CLOSED', 'request-status-deleted': request.status === 'DELETED', 'request-status-warning': request.status === 'WARNING' }">
          {{ statusLabel(request.status) }}
        </div>
      </div>
      <div class="col-12">
        <div class="req-type">
          {{ configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN }}
        </div>
        <div class="req-date">
          {{ 'request-detail.requestDate' | translate }} {{formatDateTime(request.creationDateTime)}}
        </div>
        <div *ngIf="requestDetail.clientDocumentCount == 1" class="req-doc-count">- {{requestDetail.clientDocumentCount}} {{ 'request-detail.attachment' | translate }}</div>
        <div *ngIf="requestDetail.clientDocumentCount > 1" class="req-doc-count">- {{ 'request-detail.attachments' | translate }} : {{requestDetail.clientDocumentCount}}</div>
        <div class="req-id" *ngIf="replyService.advisorPage">
          {{ 'request-detail.id' | translate }} {{ request.id }} / {{ 'request-detail.clientID' | translate }} {{ request.sender }}
        </div>
      </div>
    </div>
  </div>

  <div style="padding-bottom: 100px;" class="container-msx-fluid msg-container" *ngIf="requestDetail && requestDetail.messages as messages" [ngClass]="{'mobile': !breakpointService.isDesktop, 'middle-size': breakpointService.isMiddleSize}">
    <div *ngFor="let message of messages; let i=index" class="row-msx ">
      <div class="col col-mobile d-flex justify-content-center">
        <div class="single-msg" [class]="message.clientOrInternal == 'C' ? 'msg-client' : 'msg-internal'">
          <div class="msg-content">
            <div *ngIf="message.clientOrInternal == 'I'" class="msg-internal-title">
              <img class="icon-WTW" src='{{siteUrl}}/assets/messages/icon_WTW.svg' ><span>Willis Towers Watson</span>
            </div>
            <div class="msg-comment">{{ message.comment }}</div>
            <div *ngFor="let document of message.documents" class="row-msx">
              <div class="col" >
                <div class="file d-flex align-items-center justify-content-between" (click)="downloadDocument(document, requestDetail)" [ngClass]="{'downloadable':requestDetail.downloadable}">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="icon-file" src='{{siteUrl}}/assets/{{documentIcon(document)}}.svg' >
                    <div>
                      <div class="file-name" >{{(document.name.length>25)? (document.name | slice:0:25)+'...':(document.name) }}</div>
                      <div class="file-size">{{formatBytes(document.size)}}</div>
                    </div>
                  </div>
                  <img *ngIf="![3,16].includes(requestDetail.request.typeId)" class="icon-download" src='{{siteUrl}}/assets/messages/icon_download.svg' >
                  
                    
                  
                </div>
              </div>
            </div>

          </div>
          <div class="msg-date">{{formatDateTime(message.creationDateTime)}}</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(replyService.advisorPage && requestDetail && replyService.currentId == requestDetail.request.id ) || (requestDetail && requestDetail.request.status === 'PENDING' && showV3) " class="response-footer">
    <div class="container-msx" style="max-width: 900px;">
      <div class="col btnEchange">
        <button type="button"
              class="btnCustom btnResponse" [routerLink]="replyService.advisorPage ? ['/advisor-creation'] : ['/reply-creation']"
              skipLocationChange="true"
              routerLinkActive="active" (click)="sendMessage()">REPONDRE<img style="margin-left:10px;" src='{{siteUrl}}/assets/icon_reply_plane.svg'>
        </button>
      </div>
    </div>
  </div>
