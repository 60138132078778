import { UtilService } from 'src/app/services/util.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Requests } from '../models/requests';
import { Request } from '../models/request';
import { RequestPFN } from '../models/requestPfn';
import { RequestsPFN } from '../models/requestsPFN';
import { Client } from '../models/client';
import { RequestForm } from '../models/request-form';
import { RequestDetail } from './../models/request-detail';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  url = environment.bffUrl + '/v1';
  urlAdvisor = environment.advisorUrlAPI + '/v1';
  requests: Request[] = [];
  requestsPFN: RequestPFN[] = [];
  selectedId: number = null;
  unreadRequests: number = 0;
  unreadRequestsPfn: number = 0;

  constructor(private http: HttpClient, private utilService: UtilService) { }

  hasVisibleRequests() {
    return (this.requests.find(r => r.visible)); 
  }
  
  hasVisibleRequestsPFN() {
    return (this.requestsPFN.find(r => r.visible)); 
  }
  
  /**
   * get request by domain
  */
 getRequestsByDomain(): Observable<Requests> {
   return this.http.get<Requests>(this.url + "/requests");
  }
  
  getRequestsPFN(): Observable<RequestsPFN> {
    return this.http.get<RequestsPFN>(this.url + "/requests/other");
  }

  getRequestDetail(requestId: number): Observable<RequestDetail> {
    //  count client documents
    let count = 0;
    return this.http.get<RequestDetail>(this.url + "/requests-detail/" + requestId).pipe(
      map(r => {
        r.messages.forEach(m => count += m.documents.length);
        r.clientDocumentCount = count;
        return r;
      })
    );
  }
  getRequestDetailForAdvisor(requestId: number): Observable<RequestDetail> {
      //  count client documents
      let count = 0;
      return this.http.get<RequestDetail>(this.urlAdvisor + "/requests-detail/" + requestId).pipe(
        map(r => {
          r.messages.forEach(m => count += m.documents.length);
          r.clientDocumentCount = count;
          return r;
        })
      );
    }


  createRequest(request: RequestForm, files: any[], hashFiles: string[]) {
    var form = new FormData();
    if (files) {
      for  (var i =  0; i <  files.length; i++)  {
        form.append("files",  files[i], files[i].name );
        form.append("hashFiles", hashFiles[i] );
      }
    }
    form.append("label", request.label);
    form.append("collectivite", request.collectivite);
    form.append("groupId", request.groupId.toString());
    if (request.typeId) {
      form.append("typeId", request.typeId.toString()); //  for spring, can't cast "null" to Long
    }
    form.append("comment", this.utilService.b64EncodeUnicode(request.comment));
    form.append("nbFiles", files.length.toString());

    return this.http.post<any>(this.url + "/requests", form);
  }

  createMessageAdvisor(requestId: number, commentAdvisor: string, files: any[]) {

    var form = new FormData();
    if (files) {
      for  (var i =  0; i <  files.length; i++)  {
        form.append("files",  files[i], files[i].name );
      }
    }

    form.append("commentAdvisor", this.utilService.b64EncodeUnicode(commentAdvisor));
    form.append("nbFiles", files.length.toString());

    return this.http.post<any>(this.urlAdvisor + "/requests/" + requestId + "/messages", form);

  }
  
  createMessage(requestId: number, commentClient: string, files: any[]) {

    var form = new FormData();
    if (files) {
      for  (var i =  0; i <  files.length; i++)  {
        form.append("files",  files[i]);
      }
    }

    form.append("commentClient", this.utilService.b64EncodeUnicode(commentClient));
    form.append("nbFiles", files.length.toString());

    return this.http.post<any>(this.url + "/requests/" + requestId + "/messages", form);

  }
  getRequestsAdvisor(client: Client, requestId: number): Observable<Requests> {
      return this.http.get<Requests>(this.urlAdvisor + '/requests?domaine=' + client.domain + '&clientId=' + client.clientId + '&requestId=' + requestId);
    }
    getClientIdWithRequestId(requestId: number): Observable<Client> {
      return this.http.get<Client>(this.urlAdvisor + "/requests/" + requestId + "/client");
    }
}
