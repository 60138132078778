import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Group } from 'src/app/models/group';
import { Type } from 'src/app/models/type';
import { UtilService } from 'src/app/services/util.service';
import { ConfigService } from 'src/app/services/config.service';
import { CreationService } from '../creation.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css','../creation.component.scss','./type.component.scss']
})
export class TypeComponent implements OnInit, OnChanges {
  types: Type[] = [];
  isMobileExtranet: Boolean;

  creationLabel = '';
  creationLabelCounter = 0;

  @Input() group: Group | undefined;

  constructor(
    private utilService: UtilService,
    public creationService: CreationService,
    public configService: ConfigService) {}

  ngOnInit(): void {
    this.getTypes();
    this.setMarginTop();

    // create space (add padding) if extranet = EXM or EIM
    let extra = this.configService.extranet;
    if (extra === 'EXM' || extra === 'EIM') {
      this.isMobileExtranet = true;
    }
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.getTypes();
  }

  onSelect(type: Type): void {
    this.creationService.selectedType = type;
    this.creationService.uploadFiles = new Array();
    this.creationService.requestMessage = '';
  }
  
  onKeyUp(event: any) {
    this.creationLabelCounter = event.target.value.length;
  }

  onSubmit() {
    this.creationService.step = 3;
    this.creationService.stepMax = 3;

  }

  checkButton() {
    if (this.types.length == 0 || this.creationService.selectedType != null) {
      return false; // button is enabled
    }
    return true; // button is disabled
  }

  getTypes(): void {
    if (this.group) {
      this.types = this.group.types.filter(type => type.creation);
    }
  }

  typeImage(typeId: number) {
    return this.utilService.getTypeImageUrl(typeId);
  }

  setMarginTop() {
    if (this.creationService.groups.length > 6) {
      return 65;
    } else {
      return 50;
    }
  }

}
