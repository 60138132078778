import { TranslateService } from '@ngx-translate/core';
import { AttachmentsCheckBoxAdvisorService } from './attachments-checkbox-advisor.service';
import { DefaultComment } from './../../../models/defaultComment';
import { BindingService } from './../../../services/binding.service';
import { Binding } from './../../../models/binding';
import { ModalService } from './../../../services/modal.service';
import { FilterService } from 'src/app/pages/historical-request/filters/filter.service';
import { AttachmentRule } from './../../../models/attachmentRule';
import { ConfigService } from './../../../services/config.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CreationService } from '../creation.service';
import { RequestService } from '../../../services/request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { UtilService } from 'src/app/services/util.service';
import { NgbDateParserFormatter, NgbDateAdapter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from 'src/app/services/date-custom-formatter.service';
import { CustomAdapter } from 'src/app/services/date-custom-adapter.service';


@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css', '../creation.component.scss', './attachments.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class AttachmentsComponent implements OnInit {
    siteUrl = environment.siteUrl;
    showV3 = environment.showV3;
    messageLength = 0;
    filesSize = 0;
    // attachmentRule: AttachmentRule;
    binding: Binding;
    submited = false;
    isUpdatedComment = false;
    checkboxAdvisorStatusList = [];
    isBankUpdate: boolean;
    isEAC = false;
    modalDialogState = false;
    isContractCancel = false;

    // test advisor page
    @Input() advisorPage: boolean | undefined;
    @Input() requestId: number | undefined;

    // client reply
    @Input() isReplyPage: boolean | undefined;

    @ViewChild('fileUpload') fileUpload: ElementRef;

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private configService: ConfigService,
      private modalService: ModalService,
      public creationService: CreationService,
      private requestService: RequestService,
      private localizationService: LocalizationService,
      private translateService: TranslateService,
      private bindingService: BindingService,
      private breakpointService: BreakpointService,
      private filterService: FilterService,
      private spinnerService: NgxSpinnerService,
      private checkboxAdvisorService: AttachmentsCheckBoxAdvisorService,
      private utilService: UtilService,
      private dateAdapter: NgbDateAdapter<string>,
      private ngbCalendar: NgbCalendar) { }

      ngOnInit(): void {

      this.isEAC = this.configService.extranet === 'EAC';
      let modalStateSubscription = null;
      modalStateSubscription = this.modalService.modalState$.subscribe(state => {
        this.modalDialogState = state;
        if (this.modalDialogState) {
          modalStateSubscription.unsubscribe();
          this.modalService.setState(false);
          this.sendRequest();
        }
      });

      if (this.advisorPage) {

        const profileId = 1;
        const profileName = 'EXAAdherent';
        const groupId: number = this.creationService.selectedGroup ? this.creationService.selectedGroup.id : null;
        const typeId: number = this.creationService.selectedType ? this.creationService.selectedType.id : null;
        const attachmentRule: AttachmentRule = {id: 3, maxNb: 8,
                                                 required: false, messageRequired: true,
                                                 extensions: ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'pdf', 'txt', 'csv'],
                                                 enableComment: true, monthRequired: false, yearRequired: false, dateRequired: false, contractNumberRequired: false, motiveRequired: false
                                            };
        const defaultComment: DefaultComment = null;
        const downloadable = true;
        const creation = true;
        const consultation = true;

        this.binding = {profileId, profileName, groupId, typeId, attachmentRule, defaultComment, downloadable, creation, consultation};
        this.checkboxAdvisorService.cleanFilters();
        this.checkboxAdvisorStatusList = this.checkboxAdvisorService.checkboxStatusList;
      } else {
        this.bindingService.getBinding(
          this.creationService.selectedGroup.id,
          this.creationService.selectedType ? this.creationService.selectedType.id : null
        ).subscribe(b => {
          this.binding = b;
          this.setEACRequired();
          this.getComplexRequestFieldsValues();
          this.setContractCancelRequired();
          this.validForm();
          this.isBankUpdate = this.binding.attachmentRule.enableComment ? false : true;
          if (b.defaultComment) {
            if (this.translateService.getDefaultLang().includes('en-US')) {
              this.creationService.requestMessage = b.defaultComment.commentEN;
              this.messageLength = this.creationService.requestMessage.length;
            } else {
              this.creationService.requestMessage = b.defaultComment.commentFR;
              this.messageLength = this.creationService.requestMessage.length;
            }
          }
          });
      }

    }

    selectStatus(status) {
      this.checkboxAdvisorService.selectStatus(status);
      this.validForm();
    }
    checkedIcon(selected: boolean) {
      return this.siteUrl + (selected ? '/assets/filter/check-button-selected.svg' : '/assets/filter/check-button-unselected.svg');
    }

    onKeyUp(event: any) {
      if (!this.isUpdatedComment) {
        this.isUpdatedComment = true;
      }
      this.messageLength = event.target.value.length;
      this.validForm();
    }

    uploadFile(evt: FileList){
        const badFilesSize = [];
        const badFiles = [];
        const badFilesName = [];
        let tooManyFiles = false;
        const errorMsgs = [];
        const duplicatedFileNames = [];

        for (let i = 0; i < evt.length; i++) {
          if (this.isFileEmpty(evt[i])) {
            badFilesSize.push(evt[i].name);
          }
          else if (!this.isFileNameOk(evt[i])){
              badFilesName.push(evt[i].name);
          }
          else if (!this.isFileExtensionOk(evt[i])) {
            badFiles.push(evt[i].name);
          }
          else if (this.isDuplicatedFile(this.creationService.uploadFiles, evt[i])) {
            duplicatedFileNames.push(evt[i].name);
          }
          else {
            if (this.creationService.uploadFiles.length < this.binding.attachmentRule.maxNb) {
              this.creationService.uploadFiles.push(evt[i]);
              this.utilService.computeChecksum(evt[i]).then(hash => this.creationService.hashFiles.push(hash));
            } else {
              tooManyFiles = true;
            }
          }
        }

        if (badFilesSize.length > 0) {
          const badFilesSizeMsg = this.localizationService.translate('attachment.fileSizeEmpty');
          errorMsgs.push(badFilesSizeMsg);
        }

        if (duplicatedFileNames.length > 0) {
          const duplicatedFileNamesMsg = this.localizationService.translate('attachment.duplicatedFileNames');
          errorMsgs.push(duplicatedFileNamesMsg);
        }

        if (badFilesName.length > 0) {
          const badFilesNameMsg = this.localizationService.translate('attachment.badFileNameExtension');
          errorMsgs.push(badFilesNameMsg);
        }

        if (badFiles.length > 0) {
          let badFilesMsg = (badFiles.length === 1) ? this.localizationService.translate('attachment.badFileExtension') : this.localizationService.translate('attachment.badFilesExtension');
          badFilesMsg += ' : ' + badFiles.join(', ');
          errorMsgs.push(badFilesMsg);
        }

        if (tooManyFiles) {
          if (!this.advisorPage) {
            errorMsgs.push(this.localizationService.translate('attachment.tooManyFiles'));
          }else {
            errorMsgs.push(this.localizationService.translate('attachment.advisorTooManyFiles'));
          }
        }

        if (this.isFilesSizeTooLarge(this.creationService.uploadFiles)) {
          if (this.creationService.uploadFiles.length > 1) {
            errorMsgs.push(this.localizationService.translate('attachment.filesSizeTooLarge'));
          }
          else
            if (this.binding.typeId === 3) {
              errorMsgs.push(this.localizationService.translate('attachment.fileRIBSizeTooLarge'));
            }
            else {
              errorMsgs.push(this.localizationService.translate('attachment.fileSizeTooLarge'));
            }
        }

        if (errorMsgs.length > 0) {
          this.modalService.show({title: this.localizationService.translate('attachment.modalTitle'), texts: errorMsgs });
        }

        this.validForm();

        // clear value of input field to prevent chrome bugy behavior
        if (this.fileUpload.nativeElement.value) {
          this.fileUpload.nativeElement.value = null;
        }
    }

    deleteFile(file){
        this.filesSize -= file.size;
        this.creationService.hashFiles.splice(this.creationService.uploadFiles.indexOf(file), 1);
        this.creationService.uploadFiles.splice(this.creationService.uploadFiles.indexOf(file), 1);
        this.validForm();
    }

    cancel(){
        this.creationService.clean();
        this.router.navigate(['/'], { skipLocationChange: true });
    }

    validForm(){
      if (this.advisorPage) {

        if (this.creationService.uploadFiles.length > this.binding.attachmentRule.maxNb) {
          this.creationService.formValid = false;
        } else if (this.isFilesSizeTooLarge(this.creationService.uploadFiles)) {
          this.creationService.formValid = false;
        } else if ((this.creationService.uploadFiles.length === 0) && this.isEmpty(this.creationService.requestMessage)) {
          this.creationService.formValid = false;
        } else if (!this.checkboxAdvisorService.hasAnyCheckbox() && this.showV3) {
          this.creationService.formValid = false;
        } else {
          this.creationService.formValid = true;
        }

      } else {
        if (this.creationService.uploadFiles.length > this.binding.attachmentRule.maxNb) {
          this.creationService.formValid = false;
        } else if ((this.creationService.uploadFiles.length === 0) && (this.binding.attachmentRule.required)) {
          this.creationService.formValid = false;
        } else if (this.isEmpty(this.creationService.requestMessage) && (this.binding.attachmentRule.messageRequired) && !this.isEAC) {
          this.creationService.formValid = false;
        } else if (this.isFilesSizeTooLarge(this.creationService.uploadFiles)) {
          this.creationService.formValid = false;
        } else if (this.creationService.uploadFiles.length === 0 && this.isEmpty(this.creationService.requestMessage)  && !this.isEAC && !this.isContractCancel) {
          this.creationService.formValid = false;
        } else if (this.binding.attachmentRule.monthRequired && this.isEmpty(this.creationService.month)) {
          this.creationService.formValid = false;
        } else if (this.binding.attachmentRule.yearRequired && this.isEmpty(this.creationService.year)) {
          this.creationService.formValid = false;
        } else if (this.binding.attachmentRule.motiveRequired && this.isEmpty(this.creationService.motive)) {
          this.creationService.formValid = false;
        } else if (this.binding.attachmentRule.dateRequired && this.isEmpty(this.creationService.date)) {
          this.creationService.formValid = false;
        } else if (this.binding.attachmentRule.contractNumberRequired && this.isEmpty(this.creationService.contractNumber)) {
          this.creationService.formValid = false;
        } else {
          this.creationService.formValid = true;
        }
      }
    }

    sendRequest(): void {
      // show dialog modal
      // this should displayed alone (no other error messages) and after click of the submit button
      if (this.isEAC && this.creationService.uploadFiles.length < 3 && !this.modalDialogState) {
        this.modalService.show({title: this.localizationService.translate('attachment.modalTitle'), text: this.localizationService.translate('attachment.fileNum3'), dialog: true});
        return;
      }
      this.submited = true;
      this.spinnerService.show();
      let comment = this.isUpdatedComment ? this.creationService.requestMessage : '';
      if (this.isEAC && this.isEmpty(comment)) {
        comment = this.eacComment();
      }
      if (this.isContractCancel) {
        comment = this.cancelContractComment();
      }
      const request = {
        label : this.creationService.creationLabel,
        collectivite : this.configService.collectiviteEAC,
        comment,
        groupId : this.creationService.selectedGroup.id,
        typeId : this.creationService.selectedType ? this.creationService.selectedType.id : null
      };

      this.requestService.createRequest(request, this.creationService.uploadFiles, this.creationService.hashFiles).subscribe(data => {
                                  this.spinnerService.hide();
                                  this.creationService.clean();
                                  this.submited = false;
                                  this.requestService.selectedId = data.id;

                                  if (this.breakpointService.isDesktop) {
                                    this.router.navigate(['/historical/requests-detail', data.id], { skipLocationChange: true });
                                  } else {
                                    this.router.navigate(['/requests-detail', data.id], { skipLocationChange: true });
                                  }

                                  return data.id;
                                },
                                error => {
                                    this.submited = false;
                                });
    }

    sendMessageAdvisor(): void {
      this.submited = true;
      this.spinnerService.show();
      const commentAdvisor: string = this.isUpdatedComment ? this.creationService.requestMessage : '';

      this.requestService.createMessageAdvisor(this.requestId, commentAdvisor, this.creationService.uploadFiles).subscribe(data => {
                                  this.spinnerService.hide();
                                  this.creationService.clean();
                                  this.submited = false;
                                  this.requestService.selectedId = this.requestId;
                                  this.router.navigate(['/advisor'], { queryParams: {id: this.requestId}});
                                  return data.id;
                                });

    }

    sendMessage(): void {
      this.submited = true;
      this.spinnerService.show();
      const commentClient: string = this.isUpdatedComment ? this.creationService.requestMessage : '';

      this.requestService.createMessage(this.requestId, commentClient, this.creationService.uploadFiles).subscribe(data => {
                                  this.spinnerService.hide();
                                  this.creationService.clean();
                                  this.submited = false;
                                  this.requestService.selectedId = this.requestId;

                                  if (this.breakpointService.isDesktop) {
                                    this.router.navigate(['/historical/requests-detail', this.requestId], { skipLocationChange: true });
                                  } else {
                                    this.router.navigate(['/requests-detail', this.requestId], { skipLocationChange: true });
                                  }

                                  return data.id;
                                });

    }

    eacComment(): string {
      const monthTxt = this.localizationService.translate('attachment.month');
      const yearTxt = this.localizationService.translate('attachment.year');
      const budgetTxt = this.localizationService.translate('attachment.budget');
      return `${monthTxt}: ${this.creationService.month}
${yearTxt}: ${this.creationService.year}
${budgetTxt}: ${this.creationService.budget}`;
    }
    cancelContractComment(): string {
      const contractNumberLabel = this.localizationService.translate('request-detail.contractCancelNumber');
      const motiveLabel = this.localizationService.translate('request-detail.contractCancelMotive');
      const dateLabel = this.localizationService.translate('request-detail.contractCancelDate');
      return `${contractNumberLabel}: ${this.creationService.contractNumber}
${motiveLabel}: ${this.creationService.motive}
${dateLabel}: ${this.creationService.date}`;
    }

    isEmpty(text: string): boolean {
      return (text === null) || text.trim() === '';
    }

    isFileNameOk(file: File): boolean {

      const filename = file.name.trim().toLowerCase();
      // ['\";=]
      const regex = /[\['\\\";=\]]+/;

      const contains = filename.match(regex);

      if (contains != null && contains.length > 0) {
        return false;
      }
      else {
        return true;
      }
    }

    isFileExtensionOk(file: File): boolean {
      const parts = file.name.trim().toLowerCase().split('.');
      if (parts.length < 2) {
        return false;
      }
      const extension = parts[parts.length - 1].trim();

      const find = this.binding.attachmentRule.extensions.find(ext => {
        if (ext === extension) {
          return true;
        }
      });
      if (find) {
        return true;
      }
      else {
        return false;
      }
    }

    isDuplicatedFile(files: File[], file: File): boolean {
      for (let i = 0; i < files.length; i++) {
        if (files[i].name === file.name) {
          return true;
        }
      }
      return false;
    }

    isFilesSizeTooLarge(files: File[]): boolean {

      const MAX_FILES_SIZE = this.binding.typeId === 3 ? 2 * 1024 * 1024 : 15 * 1024 * 1024;  //  2Mo or 15Mo

      this.filesSize = 0;

      for (let i = 0; i < files.length; i++) {
        this.filesSize += files[i].size;
      }
      return this.filesSize > MAX_FILES_SIZE;
    }

    isFileEmpty(file: File): boolean {
      return file.size <= 0;
    }

    formatBytes(bytes){
      if (bytes === 0) { return '0 Ko'; }

      const k = 1024;
      const sizes = ['Ko', 'Ko', 'Mo'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    setEACRequired(){
      this.binding.attachmentRule.monthRequired = this.isEAC;
      this.binding.attachmentRule.yearRequired = this.isEAC;
    }

    setContractCancelRequired(){
      this.binding.attachmentRule.motiveRequired = this.isContractCancel;
      this.binding.attachmentRule.dateRequired = this.isContractCancel;
      this.binding.attachmentRule.contractNumberRequired = this.isContractCancel;
    }

    /**
     * This should be used in the normal flow also
     */
    private getComplexRequestFieldsValues() {
      if (this.creationService.selectedGroup.id === 23 && this.creationService.selectedType === null) { // Contract cancel
      // if(this.creationService.selectedGroup.id === 8 && this.creationService.selectedType.id === 34) { // for local tests
        this.binding.attachmentRule.messageRequired = false;
        this.isContractCancel = true;
        // this.creationService.date = this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
        const contractNumber = document.getElementById('msx-cr-contract-number') as HTMLInputElement;
        this.creationService.contractNumber = contractNumber ? contractNumber.value : '';
      }
    }
}
